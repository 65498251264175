import $ from 'jquery'
window.jQuery = $
import 'jquery.easing'
import 'jquery-validation'
import 'slick-carousel'
import 'slick-carousel/slick/slick-theme.scss'
import 'slick-carousel/slick/slick.scss'
import 'jquery-mask-plugin'

$(document).ready(function(){
  $(".slick").slick({
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  })

  scrollToBlock('.scrollToSpecifications', '.savoir')
  scrollToBlock('.scrollToApplication', '.applicationDestination')
  scrollToBlock('.scrollToContact', '.applicationDestination')
  scrollToBlock('.scrollToDescription', '.savoir')
  scrollToBlock('.scrollToSlick', '.slickWrapper')
  formContact()
  initPopup()
})


// Fonction permettant de défiller d'un block à un autre à l'aide d'un clique
function scrollToBlock (depart, destination, duration, ajustement) {
	// Paramètres optionnels
	duration   = (typeof duration   === 'undefined') ? 600 : duration
	ajustement = (typeof ajustement === 'undefined') ? 0   : ajustement

	// Défilement au clique
	$(depart).on( 'click', function() {
    if($(window).width() <= 1024) ajustement = 0
    $('html, body').animate({
      scrollTop: ( destination != '' ? $(destination).offset().top + ajustement : 0 )
    }, duration, 'easeInOutExpo')
  })
}


// Validation du formulaire de contact
function formContact() {
  validationEmail()
  formatPhone('#phone')
  var m = getMessages()
  var rules = {
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, courriel: true },
    phone: { required: true, minlength: 14 },
    message: { required: true }
  }
  var messages = {
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, courriel: m.email },
    phone: { required: m.required, minlength: m.phone },
    message: { required: m.required }
  }

  formValidation('#formulaire', '#submit', rules, messages, handleContact)
}


// Règle du email
function validationEmail() {
  jQuery.validator.addMethod('courriel', function (value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}

// Format du téléphone
function formatPhone(element) {
  $(element).mask('000 000-0000')
}


// Fonction contenant tous les messages
function getMessages() {
  return {
    required: 'Ce champ est obligatoire.',
    phone: 'Veuillez fournir un numéro de téléphone valide.',
    email: 'Veuillez fournir une adresse électronique valide.',
  }
}


// Fonction exécutant la validation de chaque formulaire
function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement: 'div',
    errorPlacement: function (error, element) {
      var placement = $(element).data('error')
      if (element.attr('type') == 'radio') {
        error.insertBefore(element.closest('.radiobox-field').find('.errorblock'))
      } else if (element.attr('type') == 'checkbox') {
        error.insertBefore(element.closest('.checkbox-field').find('.errorblock'))
      } else if (placement) {
        $(placement).append(error)
      } else {
        error.insertBefore(element)
      }
    },
    submitHandler: function () {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      else {
        return true
      }
      
    }
  })
  $(submit).click(function () {
    $(form).submit()
  })
}

// Handle le formulaire de contact
function handleContact() {
  $.request('courrielator::onFormSubmit', {
    // loading: $.oc.stripeLoadIndicator,
    data: {
      firstname: $('#firstname').val(),
      lastname: $('#lastname').val(),
      email: $('#email').val(),
      phone: $('#phone').val(),
      message: $('#message').val()
    },
    complete: function (output) {
      var response = output.responseJSON
    }
  })
}


function initPopup() {
	if (!$('html').hasClass('show-infolettrePopup')){
		setTimeout( function() {  $('html').addClass('show-infolettrePopup'); }, 500);
	}

	$('#closePopup').click( function() {
		$('html').removeClass('show-infolettrePopup');
		// $.cookie('popupInfolettre', 'yes', { expires: 7, path: '/' });
		// $.cookie('popupInfolettre', 'yes', { path: '/' });
		return false;
	});

	$('#infolettreOverlay').click( function() {
		$('html').removeClass('show-infolettrePopup');
		// $.cookie('popupInfolettre', 'yes', { expires: 7, path: '/' });
		// $.cookie('popupInfolettre', 'yes', { path: '/' });
		return false;
	});

	var validRulePopupInfolettre = {
		name : { required: true },
		courriel : { required: true, email: true },
	};
	var cutomsMessagesPopupInfolettre = {
		'name' : { required: 'Veuillez entrez votre nom.' },
		'courriel' : { required: 'Veuillez entrez votre adresse courriel.', email: 'Veuillez fournir une adresse courriel valide.' },
	};

	$("#popupInfolettre").validate({
		debug: false,
		onkeyup: false,
		rules: validRulePopupInfolettre,
		messages: cutomsMessagesPopupInfolettre,
		errorElement : 'div',
		errorPlacement: function(error, element) {
			var placement = $(element).data('error');
			if (placement) {
				$(placement).append(error)
			} else {
				error.insertBefore(element);
			}
		}
	});

	$('#submitPopupInfolettre').click( function() {
		$('#popupInfolettre').submit();
		return false;
	});
}